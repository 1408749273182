<template>
  <v-container fluid>
    <v-row no-gutters>
      <v-col cols="12" sm="8" md="8">
        <v-radio-group v-model="option" row mandatory @change="get_data" dense>
          <v-radio label="Encours" value="1"></v-radio>
          <v-radio label="Reglées" value="2"></v-radio>
          <v-radio label="Avances Non régularisés" value="3"> </v-radio>
          <v-radio label="Tous" value="4"> </v-radio>
        </v-radio-group>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="6" md="8">
        <listitems
          :list="dp_list"
          :headers="headers"
          :field="'NODP'"
          :filename="'DPs'"
          :sheetname="'DPs'"
          @rowselect="DPChange"
          :showedit="false"
          :key="klist"
          :add="false"
          :del="false"
          :Total="true"
          :laoding="loading"
        >
        </listitems>
      </v-col>
      <v-col cols="12" sm="6" md="4">
        <listitems
          :list="detail_list"
          :title="'Reglements'"
          :headers="detailheaders"
          :master="false"
          :key="aff"
          :add="false"
          :del="false"
          :Update="false"
          :showedit="false"
        >
        </listitems>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
const listitems = () => import("../components/ListItems.vue");

import ALLDP from "../graphql/DP/ALLDP.gql";

export default {
  components: {
    listitems,
  },
  props: {},
  data: () => ({
    klist: 100,
    option: "1",
    datepicker1: false,
    limite: null,
    selitem: {},
    aff: 0,
    headers: [
      {
        text: "Code",
        value: "nodp",
        selected: true,
        totlib: true,
        width: "130px",
      },

      {
        text: "Date",
        value: "dp_date",
        slot: "date",
        selected: true,
        width: "110px",
      },
      {
        text: "Béneficiare",
        align: "text-left",
        value: "tier_name",
        selected: true,
      },
      {
        text: "Type",
        value: "type_name",
        selected: true,
        width: "120px",
      },

      {
        text: "Montant",
        value: "mont_ttc",
        align: "end",
        slot: "cur",
        selected: true,
        total: "mont_ttc",
        width: "130px",
      },
      {
        text: "Reglé",
        value: "ttc_paye",
        align: "end",
        slot: "cur",
        selected: true,
        total: "ttc_paye",
        width: "130px",
      },
      {
        text: "Reste",
        value: "ttc_reste",
        align: "end",
        slot: "cur",
        total: "ttc_reste",
        selected: true,
        width: "130px",
      },
      {
        text: "Statut",
        align: "text-left",
        value: "status_name",
        selected: true,
      },
    ],
    detailheaders: [
      {
        text: "No",
        value: "no",
        selected: true,
      },
      {
        text: "Code",
        value: "nopaye",
        selected: true,
      },
      {
        text: "Date",
        value: "paye_date",
        slot: "date",
        selected: true,
      },
      {
        text: "Montant",
        value: "montant_paye",
        selected: true,
        slot: "cur",
        align: "end",
      },
      {
        text: "Reglé",
        value: "montant",
        selected: true,
        slot: "cur",
        align: "end",
      },
    ],
    isFactClosed: true,
    isPayeClosed: true,
    kp: 100,
    paiement: {},
    dp: {},
    changed: false,
    dp_list: [],
    loading: false,
  }),

  computed: {
    detail_list: function () {
      return this.dp.id > 0 ? this.dp.paiements : [];
    },
  },
  watch: {},
  created() {
    this.limite = this.$store.state.today;
  },

  mounted() {
    this.get_data();
  },

  methods: {
    async get_data() {
      let v;
      this.loading = true;
      switch (this.option) {
        case "1":
          v = {
            where: {
              AND: [
                { column: "TTC_RESTE", operator: "GT", value: 0 },
                { column: "STATUT_ID", value: "14" },
              ],
            },
          };
          break;
        case "2":
          v = {
            where: {
              AND: [
                { column: "TTC_RESTE", value: 0 },
                { column: "STATUT_ID", value: "14" },
              ],
            },
          };
          break;
        case "3":
          v = {
            where: {
              AND: [
                { column: "DP_TYPE", value: 1 },
                { column: "TTC_PAYE", operator: "GT", value: 0 },
                { column: "STATUT_ID", value: "14" },
              ],
            },
          };
          break;
        case "4":
          break;
        default:
          break;
      }
      let r = await this.requette(ALLDP, v, true);
      if (r) this.dp_list = r.alldp;
      this.loading = false;
      this.dp = {};
      this.klist++;
    },
    async requette(query, v) {
      let r;
      await this.$apollo
        .query({
          query: query,
          variables: v,
          fetchPolicy: "no-cache",
        })
        .then((data) => {
          r = data.data;
        })
        .catch((error) => {
          this.loading = false;
          this.snackbar_text = error.message;
          this.snackbar_color = "error";
          this.snackbar = true;
        });
      return r;
    },

    DPChange(item) {
      this.aff++;
      this.selitem = item;
      this.dp = item;
    },
  },
};
</script>
